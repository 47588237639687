import React from 'react'
import SyntaxHighlighter from 'react-syntax-highlighter'
import {monokai} from 'react-syntax-highlighter/dist/esm/styles/hljs'

export default ({node}) => {
  if (!node || !node.code) { return null }
  const {language, code} = node
  return (
    <SyntaxHighlighter language={language || 'text'} style={monokai} wrapLongLines="true" showLineNumbers="true">
      {code}
    </SyntaxHighlighter>
  )
}
