import React from 'react'
import Figure from './Figure'
import Code from './Code'
import getYouTubeId from 'get-youtube-id'
import YouTube from 'react-youtube'
import LatexRenderer from './Latex'
import {format} from 'date-fns'

const serializers = {
  types: {
    authorReference: ({node}) => <span>{node.author.name}</span>,
    mainImage: Figure,
    code: Code,
    youtube: ({node}) => {
      const {url} = node
      const id = getYouTubeId(url)
      return (<YouTube videoId={id} />)
    },
    math: ({node, isInline = false}) => <LatexRenderer isInline={isInline} latex={node.latex} />,
    table: ({node}) => <div>
      {/* <pre>{JSON.stringify(node, null, 2)}</pre> */}
      <table>
        <thead>
          <tr>  
            {node.rows[0].cells.map((val, idx) => <th key={idx}>{val}</th>)}
          </tr>
        </thead>
        <tbody>
          {node.rows.slice(1).map((row, row_idx) => 
            <tr key={row_idx}>
              {row.cells.map((val, idx) => <td key={idx}>{val}</td>)}
            </tr>
          )}
        </tbody>
      </table>
    </div>
  },
  marks: {
    internalLink: ({mark, children}) => {
      const {slug = {}, publishedAt} = mark.reference
      const dateSegment = format(publishedAt, 'YYYY/MM')
      const href = `/blog/${dateSegment}/${slug.current}/`
      // return <div>
      //   <pre>{JSON.stringify(mark, null, 2)}</pre>
      //   <a href={href}>{children}</a>
      // </div>
      return <a href={href}>{children}</a>
    },
    link: ({mark, children}) => {
      // Read https://css-tricks.com/use-target_blank/
      const {blank, href} = mark
      return blank
        ? <a href={href} target='_blank' rel='noopener noreferer'>{children}</a>
        : <a href={href}>{children}</a>
    }
  }
}

export default serializers
